<template>
  <div class="auth-inner-page">
    <div class="container">
      <div class="col-lg-4 col-md-6 ml-auto mr-auto">
        <div class="text-center">
          <img class="logo" :src="'/gpassist/img/logo-perti.png'" alt />
        </div>
        <form @submit.prevent="resetPassword">
          <card class="card-white">
            <template slot="header">
              <h3 class="card-title">Registro</h3>
            </template>

            <div>
              <base-input
                v-validate="'required|email'"
                name="email"
                :class="{'is-valid': getError('email')}"
                v-model="email"
                placeholder="Email"
                addon-left-icon="tim-icons icon-email-85"
              ></base-input>
            </div>

            <div slot="footer">
              <base-button
                native-type="submit"
                type="primary"
                class="mb-3"
                block
                :loading="loading"
              >Registrar</base-button>
              <p class="text-center">
                <small>
                  ¿Ya cuentas con usuario y contraseña?
                  <router-link class="link footer-link" :to="{name: 'login'}">Login</router-link>
                </small>
              </p>

              <div class="text-center" hidden>
                <a href="javascript:void(0)" class="link footer-link">¿Necesitas Ayuda?</a>
              </div>
            </div>
          </card>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      loading: false
    };
  },
  methods: {
    resetPassword() {},
    getError(e) {}
  }
};
</script>

<style lang="scss">
.auth-inner-page {
  min-height: 100vh;
  height: auto !important;
}
.auth-inner-page {
  display: flex;
  align-items: center;
  justify-content: center;
}
.register-page {
  .content {
    padding: 0 !important;
  }
}
</style>
